import CheckBoxCheckedSvg from "../../assets/icons/Checkbox_checked.svg";
import CheckBoxPartialSvg from "../../assets/icons/Checkbox_partial.svg";
import SkillsData from "../../data/SkillsData.json";
import ImgPng from "../../assets/images/img.png";

const LabelData = () => {
  let content = [];
  for (let i = 0; i < SkillsData.data.length; i++) {
    content.push(
      <div className="flex">
        <div className="font-lato-regular text-2xl ">
          {SkillsData.data[i].title}
        </div>
      </div>
    );
    for (let j = 0; j < SkillsData.data[i].data.length; j++) {
      content.push(
        <div className="flex">
          <img
            className=""
            src={
              SkillsData.data[i].data[j].status === "true"
                ? CheckBoxCheckedSvg
                : CheckBoxPartialSvg
            }
            alt="imgSvg"
          />
          <div className="font-lato-light text-xl ">
            {SkillsData.data[i].data[j].name}
          </div>
        </div>
      );
    }
  }
  return content;
};

const WindowFrame = (props) => {
  const classStyle = props.classStyle ? props.classStyle : "";

  return (
    <div
      className={`${classStyle} drop-shadow-2xl px-3 py-3 rounded-lg bg-gray-200 bg-opacity-90 backdrop-blur-sm`}
    >
      <div className="sm:flex">
        <div className=" mx-auto sm:mx-0 font-lato-light text-3xl text-center">
          Skills
        </div>
        <div className="sm:ml-auto flex invisible sm:visible mt-1">
          <div className="w-4 h-4 mx-1 bg-green-400 rounded-full shadow-sm"></div>
          <div className="w-4 h-4 mx-1 bg-yellow-400 rounded-full shadow-sm"></div>
          <div className="w-4 h-4 mx-1 bg-red-400 rounded-full shadow-sm"></div>
        </div>
      </div>
      <div className=" grid sm:grid-cols-2 sm:mt-4 h-fit">
        <div className="bg-white sm:mr-4 px-2 py-2 bg-opacity-40">
          <LabelData />
        </div>
        <div className="flex h-full invisible">
          <img
            className="hidden sm:block m-auto sm:visible opacity-90"
            src={ImgPng}
            alt="imgPng"
          />
        </div>
      </div>
      <div className="flex sm:h-1">
        <button className="mb-1 sm:mb-0  mt-4 sm:absolute mx-auto sm:mt-2 left-1/2 sm:left-auto bottom-4 sm:right-8 bg-secondary rounded-md">
          <div className="text-white text-xl font-lato-regular mx-6 my-0.5 shadow-sm">
            Next
          </div>
        </button>
      </div>
    </div>
  );
};

export default WindowFrame;
