import WorkedData from "../../data/WorkedData.json";

const Tab = (props) => {
  const selectedTab = props.selectedTab ? props.selectedTab : 0;
  const TabDetails = () => {
    let nameTab = [];

    nameTab.push(
      <div className="flex flex-col">
        <div className="font-lato-medium text-secondary text-lg ">
          {WorkedData.data[selectedTab].position}
        </div>
        <div className="font-lato-light text-base text-gray-600">
          {WorkedData.data[selectedTab].duration}
        </div>
      </div>
    );

    for (let i = 0; i < WorkedData.data[selectedTab].details.length; i++) {
      nameTab.push(
        <div className="flex flex-col">
          <div className="font-lato-regular text-base text-black ">
            {">"} {WorkedData.data[selectedTab].details[i]}
          </div>
        </div>
      );
    }
    return nameTab;
  };

  return (
    <>
      <div>
        <TabDetails />
      </div>
    </>
  );
};

export default Tab;
