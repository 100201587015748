import "./styles/App.css";
// import AppFooter from "./view/footer/AppFooter";
import HomePage from "./view/Homepage/HomePage";
// import AppHeader from "./view/header/AppHeader";
import SocialMedia from "./view/contact/SocialMedia";

function App() {
  return (
    <>
      {/* <AppHeader /> */}
      <HomePage />
      {/* <AppFooter /> */}
      <SocialMedia />
    </>
  );
}

export default App;
