import imgSvg from "../../assets/images/img1.svg";
import WindowFrame from "../../components/window_frame/WindowFrame";
import Worked from "../experience/Worked";
const HomePage = () => {
  return (
    <>
      <div className="grid justify-center z-0 my-10">
        <div className="font-lato-bold text-5xl text-secondary text-center px-5">
          Fullstack Developer
        </div>
        <div className="mt-2 font-lato-light text-3xl text-tetiary text-center px-5">
          I design, I code, and I love what I do.
        </div>
        <div className="">
          <img className="" src={imgSvg} alt="imgSvg" />
        </div>
      </div>
      <div className="grid justify-center bg-secondary z-0">
        <div className="w-4/5 mx-auto my-20">
          <div className="font-lato-bold text-5xl text-light-color text-center">
            Hi, I am Aziz. Nice to meet you.
          </div>
          <div className="mt-2 font-lato-light text-3xl text-light-color text-center">
            I am a developer with fundamental knowledgle in software design,
            development and testing. Naturally curious, love technologies and
            always eager to learn new things.
          </div>
        </div>
      </div>
      <div className="bg-split-secondary-white z-0">
        <div className="mb-20 ">
          <WindowFrame classStyle="mx-4 md:mx-auto md:w-4/5 lg:w-4/6 xl:w-3/5 mb-4" />
        </div>
      </div>
      <div className="mx-4 md:mx-auto md:w-4/5 lg:w-4/6 xl:w-3/5 mb-4">
        <div className="font-lato-bold text-4xl text-secondary mb-4">
          Where I’ve worked
        </div>
        <Worked />
      </div>
    </>
  );
};

export default HomePage;
