import {
  TiSocialFacebookCircular,
  TiSocialLinkedinCircular,
  TiSocialGithubCircular,
} from "react-icons/ti";

const SocialMedia = () => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  return (
    <>
      <div className="grid justify-center pb-4">
        <div className="flex mt-2 mx-auto">
          <div className="text-3xl text-tetiary text-center">
            <button
              onClick={() =>
                openInNewTab("https://www.linkedin.com/in/azizdin/")
              }
            >
              <TiSocialLinkedinCircular className="md:mx-2" />
            </button>
          </div>
          <div className="text-3xl text-tetiary text-center">
            <button onClick={() => openInNewTab("https://github.com/azizdin")}>
              <TiSocialGithubCircular className="md:mx-2" />
            </button>
          </div>
          <div className="text-3xl text-tetiary text-center">
            {/* <button
              onClick={() =>
                openInNewTab("https://www.facebook.com/aziz.din.5")
              }
            >
              <TiSocialFacebookCircular className="md:mx-2" />
            </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialMedia;
