import TabDetails from "../../components/Tabs/TabDetails";
import WorkedData from "../../data/WorkedData.json";
import { useState } from "react";
const Worked = (props) => {
  const classStyle = props.classStyle ? props.classStyle : "";
  const [selectedTab, setSelectedTab] = useState(0);
  const textColor = [
    "text-blue-400",
    "text-green-400",
    "text-yellow-400",
    "text-red-400",
    "text-purple-400",
  ];
  const borderColor = [
    "border-blue-400",
    "border-green-400",
    "border-yellow-400",
    "border-red-400",
    "border-purple-400",
  ];
  const TabTitle = () => {
    let nameTab = [];
    for (let i = 0; i < WorkedData.data.length; i++) {
      nameTab.push(
        <div>
          <div
            className={
              "font-lato-medium text-2xl cursor-pointer mr-4  sm:ml-2 text-center " +
              (selectedTab === i
                ? textColor[WorkedData.data[i].color] ?? " text-secondary "
                : "text-gray-400")
            }
            onClick={() => setSelectedTab(i)}
          >
            {WorkedData.data[i].company}
            {selectedTab === i ? (
              <div
                className={
                  "border-b-2 w-3/4 mx-auto " +
                    borderColor[WorkedData.data[i].color] ??
                  " border-secondary "
                }
              ></div>
            ) : (
              <></>
            )}
          </div>
        </div>
      );
    }
    return nameTab;
  };

  return (
    <>
      <div
        className={`${classStyle} drop-shadow-2xl px-3 py-3 rounded-lg bg-gray-200 bg-opacity-90 backdrop-blur-sm`}
      >
        <div className="sm:flex sm:flex-row">
          <div className="flex sm:flex-col">
            <TabTitle />
          </div>
          <div className="sm:mx-4 sm:border-r-2 sm:border-secondary"></div>
          <div className="pt-1">
            <TabDetails selectedTab={selectedTab} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Worked;
